/**
* Main app css file
* @author Bartosz Marchewka, Trivalo
*/
.dataMainSection {
  display: flex;
  font-size: 0.2in;
}

.dataSubSection {
  font-size: 0.12in;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.dataMainSection > .MuiTypography-root,
.dataSubSection > .MuiTypography-root {
  margin-right: 0.3em;
}

.dataSubSectionDesc {
  display: inline;
  color: black;
  margin-left: 5px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.logo {
  width: 40px;
}

.headerLogo {
  flex: 1 1 auto;
  text-align: center !important;
  font-size: 1.6em !important;
}

.headerHistoryBack {
  width: 30px;
  padding-top: 5px;
}

.historyBackIcon {
  margin-left: 5px;
}

.footerData {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.footerPrivacy {
  flex: 1 1 auto;
}

.footerLogo {
  flex: 0 1 auto;
}

.asset,
.location {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-left: 12px;
}

.menu-label {
  padding-left: 10px;
}

.buttonSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buttonSection > a {
  margin: 10px;
}

.contactPersons,
.works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.contactPersons > div,
.works > div {
  width: 100%;
  padding: 5px 10px 5px 10px;
}

.contactCardContent {
  padding: 12px !important;
}

#workSummary {
  padding: 4px 12px !important;
}

.contactCardAction {
  padding-top: 0px !important;
  padding-bottom: 16px !important;
  padding-left: 16px !important;
}

.appName {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.serviceRequest {
  margin: 20px 10px 10px 10px;
}

.serviceRequestSaveButton {
  width: 100%;
}

.srFormProblemCode legend,
.srFormWorkType legend,
.srPriority legend {
  max-width: 1000px;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
}

.srFormFieldRequired label:after {
  content: ' *';
}

.srFormFieldRequired legend {
  padding-right: 8px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 60vh;
}

#workSummary {
  align-items: baseline;
}

#workSummary > .MuiAccordionSummary-expandIcon {
  top: 22px;
}

#workSummary > .MuiAccordionSummary-content,
#workDetailsSection {
  flex-direction: column;
}

#workDetailsSection > p {
  margin-bottom: 0.2em;
}

.workDescTrim {
  line-height: 1.5em !important;
  height: 3em;
  overflow: hidden;
}

.workPriorityIcon {
  vertical-align: middle;
  margin-left: 5px;
  margin-bottom: 2px;
}

.workDetailsRow {
  display: flex;
  flex-direction: row;
}

.workDetailsRow > .MuiTypography-root {
  margin-right: 0.3em;
}

.srFormGrid > .MuiGrid-item {
  margin-bottom: 8px;
}

.srFormProblemCode,
.srFormWorkType,
.srPriority {
  min-width: 100% !important;
  margin-bottom: 10px !important;
}

.srFormAttachmentChip {
  margin-right: 5px;
  margin-bottom: 5px;
}

.srFormAttachmentLabel {
  margin-bottom: 5px;
}

.srFormTooBigAttachment > span {
  text-decoration: line-through;
}

.srFieldLoadingIcon,
.buttonLoadingIcon {
  background-image: url(./images/field_loading.gif);
  background-position-x: center;
  background-position-y: center;
  background-size: 60px;
  background-repeat: no-repeat;
}

.buttonLoadingIcon .MuiButton-label {
  opacity: 0.3;
}

.messageSection,
.dialogLeaveTicketContent {
  margin: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.messageSection > svg {
  width: 3em;
  height: 3em;
  align-self: center;
  margin-bottom: 1.5em;
  margin-top: 3em;
}

.dialogLeaveTicketContent > svg {
  width: 3em;
  height: 3em;
  align-self: center;
}

.dialogLeaveTicketContent > p {
  text-align: center;
}

.messageSection > a,
.messageSection > button {
  align-self: center;
  margin-top: 1.5em;
  width: 100%;
}

.dialogLeaveTicketActions {
  padding: 0px !important;
}

.dialogLeaveTicketActions > button {
  width: 100%;
  margin: 0px 10px 18px 10px;
}

.dialogLeaveTicketActions > button:not(:first-child) {
  margin-left: 0px;
}

.dialogLeaveTicketBtMarked > span {
  font-weight: bold;
}

.contactButtonLight {
  text-align: left;
  padding-left: 0px;
  font-weight: normal !important;
  margin-right: 20px !important;
}

.contactCardPersonTitle {
  margin-bottom: 15px !important;
}
